import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import getRedirectUrl from "../../utils/getRedirectUrl"

import "./RelatedResourceCard.scss"

interface Props {
  post: {
    title: string
    feature_image: string
    primary_tag: {
      slug: string
    }
    slug: string
    tags: Array<Tags>
    codeinjection_head: string
  }
}

interface Tags {
  name: string
  slug: string
}

const RelatedResourceCard = ({ post }: Props) => {
  let link_text = "Read More"
  switch (post?.tags[1]?.slug) {
    case "white-paper":
      link_text = "Read White Paper"
      break
    case "datasheet":
      link_text = "Read Datasheet"
      break
    case "case-study":
      link_text = "Read Case Study"
      break
    case "webinar":
      link_text = "Watch Webinar"
      break
    case "video":
      link_text = "Watch Video"
      break
    case "blog":
      link_text = "Read Blog"
      break
    default:
      link_text = "Read More"
  }

  const featuredImage = getImage(post.featureImageSharp)
  const external_link = getRedirectUrl(post?.codeinjection_head)

  return post ? (
    <div
      className={
        `related-res-card related-res-card--${post.tags[1]?.slug} ` +
        (featuredImage ? "" : "no-image")
      }
    >
      {external_link && (
        <a href={external_link} className="related-res-card__wrapper">
          {featuredImage && (
            <GatsbyImage
              image={featuredImage}
              alt={post.title}
              className="related-res-card__image"
              imgStyle={{
                borderRadius: "8px",
              }}
            />
          )}

          <div className="related-res-card__content">
            <div className="related-res-card__tag">{post.tags[1]?.name}</div>

            <h3 className="related-res-card__title">{post.title}</h3>

            <div className="link--with-arrow">{link_text}</div>
          </div>
        </a>
      )}
      {!external_link && (
        <Link
          to={`/${post.primary_tag.slug}/${post.slug}`}
          className="related-res-card__wrapper"
        >
          {featuredImage && (
            <GatsbyImage
              image={featuredImage}
              alt={post.title}
              className="related-res-card__image"
              imgStyle={{
                borderRadius: "8px",
              }}
            />
          )}

          <div className="related-res-card__content">
            <div className="related-res-card__tag">{post.tags[1]?.name}</div>

            <h3 className="related-res-card__title">{post.title}</h3>

            <div className="link--with-arrow">{link_text}</div>
          </div>
        </Link>
      )}
    </div>
  ) : null
}

export default RelatedResourceCard
