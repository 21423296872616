import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import RelatedResourceCard from "../components/RelatedResourceCard"
import MetaData from "../components/meta"

// Import styles
import "../styles/traefikee.scss"

// Images
import traefikEELogo from "../images/logos/traefik-enterprise-logo--white.svg"
import heroIllustration from "../images/traefikee/header/hero-illustration.svg"
import Quotes from "../images/inline-svgs/quote.svg"
import features__allinone from "../images/traefikee/features/cube.svg"
import features__observability from "../images/traefikee/features/observability.svg"
import features__smooth_op_icon from "../images/traefikee/features/operations.svg"
import features__routing_icon from "../images/traefikee/features/routing.svg"
import features__security_icon from "../images/traefikee/features/security.svg"
import features__cluster_icon from "../images/traefikee/features/scalabitlity.svg"
import partners__red_hat from "../images/traefikee/extras/red-hat-open-shift.svg"
import partners__d2iq from "../images/traefikee/extras/d-2-iq.svg"
import partners__kubernetes from "../images/traefikee/extras/kubernetes.svg"
import benefits__network from "../images/traefikee/benefits/network.svg"
import benefits__simplify from "../images/traefikee/benefits/cloud-app.svg"
import benefits__complexity from "../images/traefikee/benefits/reduce-complexity-icon.svg"
import benefits__visibility from "../images/traefikee/benefits/cluster-visibility-icon.svg"
import illustration__connect from "../images/traefikee/features/connect/connect-illustration.svg"
import illustration__secure from "../images/traefikee/features/secure/secure-illustration.svg"
import illustration__monitor from "../images/traefikee/features/monitor/monitor-illustration.svg"
import ecosystem__kubernetes from "../images/traefikee/ecosystems/kubernetes-logo.svg"
import ecosystem__docker from "../images/traefikee/ecosystems/docker.svg"
import ecosystem__redhat from "../images/traefikee/ecosystems/red-hat-open-shift.svg"
import ecosystem__azure from "../images/traefikee/ecosystems/microsoft-azure/microsoft-azure.svg"
import ecosystem__google_cloud from "../images/traefikee/ecosystems/google-cloud.svg"
import ecosystem__consul from "../images/traefikee/ecosystems/consul/consul.svg"
import ecosystem__http2 from "../images/traefikee/ecosystems/http-2.svg"
import ecosystem__tcpip from "../images/traefikee/ecosystems/tcpip.svg"
import ecosystem__grafana from "../images/traefikee/ecosystems/grafana.svg"
import ecosystem__instana from "../images/traefikee/ecosystems/instana.svg"
import EmailInputIcon from "../images/inline-svgs/email-input-icon.svg"
import details__video from "../images/traefikee/details/traefikee-dashboard.mp4"
import details__ingress from "../images/traefikee/details/components/ingress.svg"
import details__mesh from "../images/traefikee/details/components/service-mesh.svg"
import details__api from "../images/traefikee/details/components/api-gateway.svg"
import details__help from "../images/traefikee/details/components/help-wheel.svg"
import servicemesh__video from "../images/traefikee/service-mesh/service-mesh.mp4"

interface Props {
  data: any
  location: any
}

const TraefikEEPage = ({ data, location }: Props) => {
  const traefikee_announcement = data.traefikee_announcement
  const related_resources = data.related_resources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="traefikee">
        {/* Hero Section */}
        <section className="traefikee-intro">
          <div className="traefikee-intro__main">
            <div
              className="traefikee-intro__updates"
              dangerouslySetInnerHTML={{ __html: traefikee_announcement?.html }}
            ></div>

            <div className="traefikee-intro__logo">
              <h1>
                <img
                  src={traefikEELogo}
                  alt="Traefik Enterprise - Cloud-native load balancer and ingress controller for Kubernetes"
                />
              </h1>
            </div>

            <h2>Connect, Secure, and Monitor Microservices at Scale</h2>

            <div className="traefikee-intro__cta">
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/get-traefik-enterprise-free-for-30-days")
                }
                className="button--primary button--with-shadow button--extra-padding"
              >
                Free Trial
              </button>
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/buy-traefik-enterprise")
                }
                className="button--primary button--primary--white button--with-shadow button--extra-padding"
              >
                Get Pricing
              </button>
            </div>
          </div>

          <div className="traefikee-intro__screenshot">
            <img src={heroIllustration} alt="traefik enterprise illustration" />
          </div>
        </section>

        {/* Details Section */}
        <section className="traefikee-details page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>What is Traefik Enterprise?</h2>
            </div>

            <div className="columns">
              <div className="content">
                <p>
                  Traefik Enterprise is a
                  <strong> unified cloud-native networking solution </strong>
                  that brings <strong> API management</strong>,
                  <strong> ingress control</strong>, and
                  <strong> Service Mesh</strong> all together in one simple
                  control plane. Traefik Enterprise eases microservices
                  networking complexity for developers and operations teams
                  across the organization.
                </p>

                <p>
                  Built on top of open source Traefik Proxy and Traefik Mesh,
                  Traefik Enterprise brings exclusive all-in-one,
                  highly-available, scalable, and distributed features combined
                  with premium bundled support for production grade deployments.
                </p>

                <p>
                  With hybrid support for both legacy and cloud-native
                  applications, Traefik Enterprise is the best solution to help
                  companies migrate progressively and safely to a microservices
                  platform.
                </p>
              </div>
              <div className="image">
                <div className="fake-browser">
                  <div className="fake-browser__controls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <video autoPlay muted loop height={415}>
                    <source src={details__video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>

            <div className="components-wrapper">
              <div className="support">
                <img src={details__help} alt="Enterprise 24/7 support" />
                <div>
                  <strong>Enterprise 24/7 support</strong>
                  <p>Direct access to the Traefik core team</p>
                </div>
              </div>

              <div className="components">
                <div className="component">
                  <img src={details__ingress} alt="Ingress" />
                  <h3>Ingress</h3>
                  <p>
                    Incoming traffic management based on open-source Traefik
                  </p>
                </div>
                <div className="plus-icon">+</div>
                <div className="component">
                  <img src={details__mesh} alt="Service mesh" />
                  <h3>Service Mesh</h3>
                  <p>
                    Service to service communication based on open-source
                    Traefik Mesh
                  </p>
                </div>
                <div className="plus-icon">+</div>
                <div className="component">
                  <img src={details__api} alt="API Gateway" />
                  <h3>API Gateway</h3>
                  <p>
                    Manage, secure, accelerate, and monitor APIs and services
                  </p>
                </div>
              </div>

              {/* <div className="swiggly-line">
                <img src={details__shape} />
              </div> */}
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="traefikee-testimonials page-content-section">
          <div className="wrapper-1200">
            <div className="testimonials">
              <div className="testimonial">
                <div className="icon">
                  <StaticImage
                    src="../images/traefikee/details/ebay-classifieds/ebay-logo.png"
                    alt="Ebay Classifieds"
                    layout="fixed"
                    width={110}
                    height={61}
                  />
                </div>
                <p className="message">
                  "We needed an advanced load balancer and an API Gateway for
                  advanced routing to control traffic flow with flexible
                  configuration and automation capabilities. Traefik gives us
                  flexibility and avoid any vendor lock-in."
                </p>
                <span className="person">Wim Fournier</span>
                <p className="designation">Site Reliability Engineer</p>
                <div className="quotes">
                  <Quotes />
                </div>
              </div>

              <div className="testimonial">
                <div className="icon">
                  <StaticImage
                    src="../images/traefikee/details/expedia/expedia-logo.png"
                    alt="Expedia"
                    layout="fixed"
                    width={138}
                    height={39}
                  />
                </div>
                <p className="message">
                  "We like the simplicity of Traefik. We love the Traefik
                  architecture, it’s fast and robust. That’s why infrastructure
                  teams love it."
                </p>
                <span className="person">Alex Antonov</span>
                <p className="designation">Distinguished Engineer</p>
                <div className="quotes">
                  <Quotes />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="traefikee-features-all page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Traefik Enterprise Features</h2>
            </div>

            <div className="features">
              <div className="feature">
                <img
                  src={features__allinone}
                  alt="All-in-one Ingress, Mesh and API management"
                />
                <h3>All-in-one Ingress, Mesh and API management</h3>
                <ul>
                  <li>
                    <p>Ingress management</p>
                  </li>
                  <li>
                    <p>Service Mesh: service-to-service communication</p>
                  </li>
                  <li>
                    <p>API Gateway</p>
                  </li>
                  <li>
                    <p>API Portal</p>
                  </li>
                </ul>
              </div>

              <div className="feature">
                <img
                  src={features__routing_icon}
                  alt="Traffic Management with Traefik Enterprise"
                />
                <h3>Traffic Management</h3>
                <ul>
                  <li>
                    <p>HTTP, HTTP/2, TCP, UDP, Websockets, GRPC</p>
                  </li>
                  <li>
                    <p>Mirroring</p>
                  </li>
                  <li>
                    <p>Canary deployments</p>
                  </li>
                  <li>
                    <p>Stickiness</p>
                  </li>
                  <li>
                    <p>Active health checks</p>
                  </li>
                  <li>
                    <p>
                      Middlewares (circuit breakers, automatic retries, HTTP
                      cache, buffering, response compression, headers)
                    </p>
                  </li>
                  <li>
                    <p>Custom middlewares through public and private plugins</p>
                  </li>
                  <li>
                    <p>Distributed in-flight request limiting</p>
                  </li>
                  <li>
                    <p>
                      Distributed rate limiting (per-IP, per-host, per-header)
                    </p>
                  </li>
                </ul>
              </div>

              <div className="feature">
                <img
                  src={features__cluster_icon}
                  alt="Highly available & scalable load balancer and Kubernetes ingress"
                />
                <h3>Availability &amp; Scalability</h3>
                <ul>
                  <li>
                    <p>One line cluster deployment through CLI</p>
                  </li>
                  <li>
                    <p>Controllers &amp; proxies resilience</p>
                  </li>
                  <li>
                    <p>Scalability (adaptive scale out)</p>
                  </li>
                  <li>
                    <p>Connection draining for progressive updates</p>
                  </li>
                  <li>
                    <p>Backup &amp; Restore</p>
                  </li>
                </ul>
              </div>

              <div className="feature">
                <img
                  src={features__security_icon}
                  alt="Load balancer and Kubernetes ingress with authentication and encryption"
                />
                <h3>Security</h3>
                <ul>
                  <li>
                    <p>OpenID Connect (OIDC)</p>
                  </li>
                  <li>
                    <p>JWT authentication</p>
                  </li>
                  <li>
                    <p>LDAP authentication</p>
                  </li>
                  <li>
                    <p>Open Policy Agent (OPA)</p>
                  </li>
                  <li>
                    <p>oAuth2 authentication</p>
                  </li>
                  <li>
                    <p>HMAC authentication</p>
                  </li>
                  <li>
                    <p>Automatic HTTPS</p>
                  </li>
                  <li>
                    <p>Distributed Let's Encrypt</p>
                  </li>
                  <li>
                    <p>Unlimited Let’s Encrypt certificates</p>
                  </li>
                  <li>
                    <p>Multi-cluster Let’s Encrypt agent</p>
                  </li>
                  <li>
                    <p>Custom certificates</p>
                  </li>
                  <li>
                    <p>Encrypted cluster communication</p>
                  </li>
                  <li>
                    <p>Vault (by Hashicorp) support</p>
                  </li>
                </ul>
              </div>

              <div className="feature">
                <img src={features__observability} alt="Observability" />
                <h3>Observability</h3>
                <ul>
                  <li>
                    <p>Cluster-wide dashboard</p>
                  </li>
                  <li>
                    <p>Proxies health check</p>
                  </li>
                  <li>
                    <p>Distributed tracing (Jaeger, Open Tracing, Zipkin)</p>
                  </li>
                  <li>
                    <p>
                      Real-time traffic metrics (Datadog, Grafana, InfluxDB,
                      Prometheus, StatsD)
                    </p>
                  </li>
                </ul>
              </div>

              <div className="feature">
                <img
                  src={features__smooth_op_icon}
                  alt="Cloud Native Migration"
                />
                <h3>Cloud Native Migration</h3>
                <ul>
                  <li>
                    <p>Hybrid routing (legacy and cloud-native)</p>
                  </li>
                  <li>
                    <p>Legacy infrastructure support</p>
                  </li>
                  <li>
                    <p>Progressive migration model</p>
                  </li>
                  <li>
                    <p>GitOps-workflow ready</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="cta">
              <Link
                to="/pricing/"
                className="button--secondary button--secondary--with-arrow"
              >
                Compare Traefik and Traefik Enterprise
              </Link>
            </div>
          </div>
        </section>

        <section className="traefikee-service-mesh page-content-section">
          <div className="wrapper-1200">
            <div className="columns">
              <div className="content">
                {/* <div className="traefikee-service-mesh__badge">New!</div> */}
                <h2>Traefik Enterprise now includes Service Mesh</h2>
                <p>
                  Consolidate your networking stack with an integrated and
                  easy-to-use ingress and service mesh.
                </p>
                <p>
                  Traefik Enterprise enhanced dashboard with service mesh
                  observability of internal east-west traffic.
                </p>
                <a
                  href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
                  className="button--primary button--extra-padding"
                >
                  Free Trial
                </a>
              </div>
              <div className="image">
                <div className="fake-browser">
                  <div className="fake-browser__controls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <video autoPlay muted loop height={415}>
                    <source src={servicemesh__video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="traefikee-kubernetes page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>
                First-Class Ingress For Enterprise Kubernetes Distributions
              </h2>
            </div>
            <ul>
              <li>
                <div className="logo">
                  <img src={partners__red_hat} alt="Red hat open shift" />
                </div>
                <div className="title">Red Hat OpenShift Certified</div>
              </li>
              <li>
                <div className="logo">
                  <img src={partners__kubernetes} alt="Kubernetes" />
                </div>
                <div className="title">Kubernetes Operator</div>
              </li>
              <li>
                <div className="logo">
                  <img src={partners__d2iq} alt="D2IQ" />
                </div>
                <div className="title">D2IQ official partner</div>
              </li>
            </ul>
            <div className="buttons">
              <button
                onClick={() =>
                  (window.location.href =
                    "https://info.traefik.io/get-traefik-enterprise-free-for-30-days")
                }
                className="button--secondary button--secondary--with-arrow"
              >
                Try Traefik Enterprise
              </button>
            </div>
          </div>
        </section>

        {/* Architecture Section */}
        <section className="traefikee-architecture page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>
                Powerful cloud-native networking for ingress and service mesh
              </h2>
              <p className="sub-title">
                Split into ingress proxies, mesh proxies, and controllers,
                Traefik Enterprise supports clustered deployments to increase
                security, scalability and high availability.
              </p>
            </div>

            <div className="page-content-section__body--columns">
              <div className="content">
                <p>
                  <strong>Ingress proxies</strong> are worker nodes that accept
                  requests from the external network and forward them to
                  services running on the cluster, based on custom rule
                  definitions and behaviors.
                </p>
                <p>
                  <strong>Mesh proxies</strong> manage internal communications
                  between services on the cluster so that they can work
                  together, while providing such features as inter-service
                  authentication, rate limiting, and traffic splitting.
                </p>
                <p>
                  <strong>Controllers</strong> query the network infrastructure
                  to generate the appropriate routing configuration, which they
                  then distribute to the proxy nodes. Controllers also store all
                  data about the cluster, which can be accessed via an API.
                </p>

                <p>
                  All Traefik Enterprise node types can be scaled horizontally
                  by adding more nodes. Together, they form a high-availability
                  cluster that maintains its own health autonomously.
                </p>
              </div>
              <div className="image">
                <StaticImage
                  src="../images/traefikee/architecture/traefik-enterprise-diagram.png"
                  alt="Traefik Enterprise Diagram"
                  width={810}
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="traefikee-benefits">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Benefits</h2>

            <div className="benefits">
              <div className="benefit">
                <img
                  src={benefits__network}
                  alt="Run Everywhere, Integrate Everything"
                />
                <h3>Run Everywhere, Integrate Everything</h3>
                <p>
                  Deploy applications anywhere, on-premises or in the cloud, and
                  natively integrate with leading infrastructure tooling.
                </p>
              </div>

              <div className="benefit">
                <img
                  src={benefits__simplify}
                  alt="Simplify Apps Modernization"
                />
                <h3>Simplify Apps Modernization</h3>
                <p>
                  Ease transition of legacy applications to cloud-native
                  microservices architectures.
                </p>
              </div>

              <div className="benefit">
                <img
                  src={benefits__complexity}
                  alt="Reduce complexity while deploying, managing, and scaling applications with Traefik Enterprise"
                />
                <h3>Reduce Complexity</h3>
                <p>
                  Save time and give better consistency while deploying,
                  managing, and scaling applications by leveraging dynamic and
                  automatic Traefik Enterprise features.
                </p>
              </div>

              <div className="benefit">
                <img
                  src={benefits__visibility}
                  alt="Deploy applications on-premises or in the cloud with Traefik Enterprise"
                />
                <h3>Complete cluster visibility</h3>
                <p>
                  Improve the application development and delivery cycle by
                  giving developers the visibility and ownership of their
                  services.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="traefikee-features">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>
              Traefik Enterprise automatically connects, secures and monitors
              your cloud-native applications in any environment
            </h2>
            <div className="buttons">
              <a
                href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
                className="button--secondary button--secondary--white button--secondary--with-arrow button--secondary--with-arrow--white"
              >
                Try Traefik Enterprise
              </a>
            </div>

            {/* Connect */}
            <div className="feature">
              <div className="content">
                <h3>Connect</h3>
                <p>
                  Effortlessly map any kind of applications to infrastructure
                  and easily scale out to meet production traffic needs.
                </p>
                <ul className="list--with-check-icon">
                  <li>
                    <span className="text">
                      Out-of-the-box ingress and service mesh
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Auto-Discovery of services in highly dynamic environments
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Highly Available, and Fault tolerant, ensuring your data
                      is always available and safe
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Highly Scalable allowing to add or shrink nodes as needed,
                      automatically, by using auto-scaling tools
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Cloud provider and technology agnostic
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Fully customize your routes with Middlewares
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Rolling out releases to a subset of users with Canary
                      Deployments
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Fork incoming requests and send it to different services
                      with Mirroring
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      An internal provider to configure additional routers,
                      services and middleware outside of your orchestrator
                    </span>
                  </li>
                </ul>
              </div>
              <div className="illustration">
                <img
                  src={illustration__connect}
                  alt="Connect any applications to infrastructure with Traefik Enterprise"
                />
              </div>
            </div>

            {/* Secure */}
            <div className="feature">
              <div className="illustration">
                <img
                  src={illustration__secure}
                  alt="End-to-end network and application encryption with Traefik Enterprise"
                />
              </div>
              <div className="content">
                <h3>Secure</h3>
                <p>
                  Put security at the forefront with end-to-end network and
                  application encryption.
                </p>
                <ul className="list--with-check-icon">
                  <li>
                    <span className="text">
                      Run your application over HTTPS
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Let’s Encrypt support to automatically manage certificates
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Custom certificates integration
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Encrypted communications between nodes, and separated
                      responsibilities to ensure reliable security
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Access control and authorization with LDAP, JWT and OIDC
                      support
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Monitor */}
            <div className="feature">
              <div className="content">
                <h3>Monitor</h3>
                <p>
                  End-to-end visibility for better insight into application
                  uptime and performance; simplify operations and
                  troubleshooting with real-time observability in dynamic
                  environments.
                </p>
                <ul className="list--with-check-icon">
                  <li>
                    <span className="text">
                      Dashboard to visualize your cluster nodes status and
                      configuration, and real-time traffic metrics
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Traffic management with distributed features like Rate
                      limiting, Circuit breakers, and Retries
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Track health of your web applications through automated
                      collections of metrics
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Tracing system to visualize applications flows in your
                      infrastructure
                    </span>
                  </li>
                </ul>
              </div>
              <div className="illustration">
                <img
                  src={illustration__monitor}
                  alt="Simplify operations and troubleshooting with Traefik Enterprise"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Ecosystem Section */}
        <section className="traefikee-ecosystem">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Traefik Enterprise Ecosystem</h2>
            <div className="groups">
              <div className="group">
                <div className="title">Orchestrators</div>
                <div className="items">
                  <div className="item">
                    <img src={ecosystem__kubernetes} alt="kubernetes" />
                  </div>
                  <div className="item">
                    <img src={ecosystem__docker} alt="docker" />
                  </div>
                  <div className="item">
                    <img src={ecosystem__redhat} alt="red hat open shift" />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__amazon_ecs.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Amazon ECS"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__rancher.childImageSharp.gatsbyImageData
                      }
                      alt="Rancher"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__mesos.childImageSharp.gatsbyImageData
                      }
                      alt="Mesos"
                    />
                  </div>
                </div>
              </div>

              <div className="group">
                <div className="title">Cloud Services</div>
                <div className="items">
                  <div className="item">
                    <img src={ecosystem__azure} alt="microsoft azure" />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__amazon_ecs.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Amazon ECS"
                    />
                  </div>
                  <div className="item">
                    <img src={ecosystem__google_cloud} alt="google cloud" />
                  </div>
                </div>
              </div>

              <div className="group">
                <div className="title">Service Discovery</div>
                <div className="items">
                  <div className="item">
                    <img src={ecosystem__consul} alt="consul" />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__amazon_dynamodb.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Amazon DynamoDB"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__etcd.childImageSharp.gatsbyImageData
                      }
                      alt="etcd"
                    />
                  </div>
                </div>
              </div>

              <div className="group">
                <div className="title">Supported Protocols</div>
                <div className="items">
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__websocket.childImageSharp
                          .gatsbyImageData
                      }
                      alt="websocket"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__grpc.childImageSharp.gatsbyImageData
                      }
                      alt="grpc"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__lets_encrypt.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Let's encrypt"
                    />
                  </div>
                  <div className="item">
                    <img src={ecosystem__http2} alt="http2" />
                  </div>
                  <div className="item">
                    <img src={ecosystem__tcpip} alt="tcp/udp" />
                  </div>
                </div>
              </div>

              <div className="group">
                <div className="title">Monitoring</div>
                <div className="items">
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__prometheus.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Prometheus"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__stasd.childImageSharp.gatsbyImageData
                      }
                      alt="StatsD"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__influxdata.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Influx Data"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__datadog.childImageSharp.gatsbyImageData
                      }
                      alt="Datadog"
                    />
                  </div>
                  <div className="item">
                    <img src={ecosystem__grafana} alt="grafana labs" />
                  </div>
                </div>
              </div>

              <div className="group">
                <div className="title">Tracing</div>
                <div className="items">
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__jaeger.childImageSharp.gatsbyImageData
                      }
                      alt="Jaeger"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__zipkin.childImageSharp.gatsbyImageData
                      }
                      alt="Zipkin"
                    />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__opentracing.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Opentracing"
                    />
                  </div>
                  <div className="item">
                    <img src={ecosystem__instana} alt="instana" />
                  </div>
                  <div className="item">
                    <GatsbyImage
                      image={
                        data.ecosystem__haystack.childImageSharp.gatsbyImageData
                      }
                      alt="haystack"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Subscribe Section */}
        <section className="traefikee-trial">
          <div className="wrapper-1200">
            <div className="content">
              <div className="heading">
                Try Traefik Enterprise Free for 30 days
              </div>
              <div className="sub-heading">Free. Easy Setup.</div>

              <div className="traefikee-trial__form">
                <form
                  action="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
                  method="get"
                >
                  <div className="email-field">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your work email"
                    />
                    <div className="email-symbol">
                      <EmailInputIcon />
                    </div>
                  </div>
                  <input value="Get Started" type="submit" />
                </form>
              </div>
            </div>
          </div>
          <div className="image">
            <img
              srcSet="
                /images/traefikee/subscribe/dark-clay.png 1x,
                /images/traefikee/subscribe/dark-clay@2x.png 2x,
                /images/traefikee/subscribe/dark-clay@3x.png 3x
              "
              src="/images/traefikee/subscribe/dark-clay.png"
            />
          </div>
        </section>

        {/* Related Resources */}
        {related_resources.length > 0 && (
          <section className="traefikee-resources">
            <div className="wrapper-1200">
              <hr className="hr-tiny" />
              <h2>Related Resources</h2>
              <div className="related-resource-feed">
                {related_resources.map(({ node }) => (
                  <RelatedResourceCard key={node.id} post={node} />
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

export default TraefikEEPage

export const query = graphql`
  {
    traefikee_announcement: ghostPage(
      slug: { eq: "traefikee-updates-announcement-banner" }
    ) {
      html
    }

    ecosystem__amazon_ecs: file(
      relativePath: { eq: "traefikee/ecosystems/amazon/amazon-ecs-logo.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 61, height: 65, layout: FIXED)
      }
    }

    ecosystem__rancher: file(
      relativePath: { eq: "traefikee/ecosystems/rancher/rancher.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    ecosystem__mesos: file(
      relativePath: { eq: "traefikee/ecosystems/mesos/mesos.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 130, height: 46, layout: FIXED)
      }
    }

    ecosystem__amazon_dynamodb: file(
      relativePath: {
        eq: "traefikee/ecosystems/amazon-dynamodb/amazon-dynamodb-logo.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 119, height: 50, layout: FIXED)
      }
    }

    ecosystem__etcd: file(
      relativePath: { eq: "traefikee/ecosystems/etcd/etcd-logo.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 113, height: 40, layout: FIXED)
      }
    }

    ecosystem__websocket: file(
      relativePath: { eq: "traefikee/ecosystems/websocket/websocket-logo.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 142, height: 31, layout: FIXED)
      }
    }

    ecosystem__grpc: file(
      relativePath: { eq: "traefikee/ecosystems/grpc/grpc-logo.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 104, height: 37, layout: FIXED)
      }
    }

    ecosystem__lets_encrypt: file(
      relativePath: {
        eq: "traefikee/ecosystems/lets-encrypt/lets-encrypt-logo.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 143, height: 33, layout: FIXED)
      }
    }

    ecosystem__prometheus: file(
      relativePath: {
        eq: "traefikee/ecosystems/prometheus/prometheus-logo.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 139, height: 35, layout: FIXED)
      }
    }

    ecosystem__stasd: file(
      relativePath: { eq: "traefikee/ecosystems/stats/stats-d.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 123, height: 44, layout: FIXED)
      }
    }

    ecosystem__influxdata: file(
      relativePath: { eq: "traefikee/ecosystems/influxdata/influxdata.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 137, height: 27, layout: FIXED)
      }
    }

    ecosystem__datadog: file(
      relativePath: { eq: "traefikee/ecosystems/datadog/datadog.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 142, height: 36, layout: FIXED)
      }
    }

    ecosystem__jaeger: file(
      relativePath: { eq: "traefikee/ecosystems/jaeger/jaeger-logo.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 118, height: 38, layout: FIXED)
      }
    }

    ecosystem__zipkin: file(
      relativePath: { eq: "traefikee/ecosystems/zipkin/zipkin.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 107, height: 68, layout: FIXED)
      }
    }

    ecosystem__opentracing: file(
      relativePath: { eq: "traefikee/ecosystems/opentracing/opentracing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 107, height: 68, layout: FIXED)
      }
    }

    ecosystem__haystack: file(
      relativePath: { eq: "traefikee/ecosystems/haystack/haystack.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 107, height: 68, layout: FIXED)
      }
    }

    related_resources: allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: { eq: "hash-traefik-enterprise-related-resource" }
          }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }

    current_page: ghostPage(slug: { eq: "traefikee" }) {
      ...PageMetaFields
    }
  }
`
